import React, { useState, useEffect } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faPhotoFilm, faClipboardUser, faTrophy, faDice, faShop } from "@fortawesome/free-solid-svg-icons";

import './navbar.scss';

function Navbar() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const checkScrollTop = () => {
    if (window.pageYOffset > 300) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, []);

  return (
    <>
      {/* Navbar pour écrans larges */}
      <nav className='navbar'>
        <ul>
          <li><ScrollLink to="accueil" spy={true} smooth={true} offset={-70} duration={500}>ACCUEIL</ScrollLink></li>
          <li><ScrollLink to="cardgame" spy={true} smooth={true} offset={-70} duration={500}>CARD GAME</ScrollLink></li>
          <li><ScrollLink to="studio-graphique" spy={true} smooth={true} offset={-70} duration={500}>STUDIO GRAPHIQUE</ScrollLink></li>
          <li><ScrollLink to="staff" spy={true} smooth={true} offset={-70} duration={500}>STAFF</ScrollLink></li>
          {/* <li><ScrollLink to="partner" spy={true} smooth={true} offset={-70} duration={500}>PARTENAIRES</ScrollLink></li> */}
          <li><Link to='https://tournament.lespixionautes.fr'>TOURNAMENT</Link></li>
          <li><Link to='https://boutique.lespixionautes.fr'>BOUTIQUE</Link></li>

        </ul>
        {showScrollButton && (
          <button className="scrollTop" onClick={scrollToTop}>
            ↑
          </button>
        )}
      </nav>

      {/* Navbar pour petits écrans */}
      <nav className='navbar-phone'>
        <ul>
          <li><ScrollLink to="accueil" spy={true} smooth={true} offset={-70} duration={500}><FontAwesomeIcon icon={faHouse} /></ScrollLink></li>
          <li><ScrollLink to="cardgame" spy={true} smooth={true} offset={-70} duration={500}><FontAwesomeIcon icon={faDice} /></ScrollLink></li>
          <li><ScrollLink to="studio-graphique" spy={true} smooth={true} offset={-70} duration={500}><FontAwesomeIcon icon={faPhotoFilm} /></ScrollLink></li>
          <li><ScrollLink to="staff" spy={true} smooth={true} offset={-70} duration={500}><FontAwesomeIcon icon={faClipboardUser} /></ScrollLink></li>
          <li><Link to="https://tournament.lespixionautes.fr"><FontAwesomeIcon icon={faTrophy}/></Link></li>
          <li><Link to="https://boutique.lespixionautes.fr"><FontAwesomeIcon icon={faShop}/></Link></li>

        </ul>

      </nav>
    </>
  );
}

export default Navbar;
