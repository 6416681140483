import React, { useState, useEffect } from 'react';
import './PlayerCardValo.scss';
import LogoAceOfPix from '../../../assets/overlay/LOGO_ACE_OF_PIX.png'
function TestOverlay() {
  const [playerCards, setPlayerCards] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [remainingTime, setRemainingTime] = useState(10); 

  useEffect(() => {
    const fetchPlayerCards = async () => {
      try {
        const response = await fetch('https://valorant-api.com/v1/playercards?language=fr-FR');
        const data = await response.json();
        setPlayerCards(data.data); 
      } catch (error) {
        console.error("Erreur lors de la récupération des cartes : ", error);
      }
    };
    fetchPlayerCards();
  }, []);

  const getRandomIndex = () => {
    return Math.floor(Math.random() * playerCards.length);
  };

  const startCarousel = () => {
    setIsAnimating(true);
    setRemainingTime(10); 

    let intervalId = setInterval(() => {
      setCurrentCardIndex(getRandomIndex()); 
    }, 150);

    const timerId = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerId);
          clearInterval(intervalId);
          setIsAnimating(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      clearInterval(timerId);
      setIsAnimating(false);
    }, 10000); //10000
  };

  return (
    <div className='playercard-overlay'>
      <img className='logo-playercard' src={LogoAceOfPix} alt="" />
      {/* <h1>({playerCards.length})</h1> */}


      {playerCards.length > 0 && (
        <div className="carousel">
          {/* Images à gauche */}
          {Array.from({ length: 3 }).map((_, index) => {
            const leftIndex = (currentCardIndex - 3 + index + playerCards.length) % playerCards.length;
            return (
              <img
                key={index}
                src={playerCards[leftIndex]?.largeArt}
                alt={playerCards[leftIndex]?.displayName}
                className="player-card left-card"
              />
            );
          })}

          {/* Image du milieu */}
          <img
            src={playerCards[currentCardIndex]?.largeArt}
            alt={playerCards[currentCardIndex]?.displayName}
            className="player-card center-card"
          />

          {/* Images à droite */}
          {Array.from({ length: 3 }).map((_, index) => {
            const rightIndex = (currentCardIndex + 1 + index) % playerCards.length;
            return (
              <img
                key={index}
                src={playerCards[rightIndex]?.largeArt}
                alt={playerCards[rightIndex]?.displayName}
                className="player-card right-card"
              />
            );
          })}
        </div>
      )}
      <h2>{playerCards[currentCardIndex]?.displayName}</h2>

      <button className='button-carrousel' onClick={startCarousel} disabled={isAnimating}>
        {isAnimating ? `Carrousel en cours : ${remainingTime}s` : 'Start'}
      </button>
      <div className='rules-playercard'>
        <p className='green'>Si la carte tirée est identique à une carte de la collection de Pix <br /> un pass de combat sera offert à un SUB.</p>
        <p className='or'>Développé par <strong>HellFire</strong> pour les pixionautes</p>
      </div>

    </div>
  );
}

export default TestOverlay;
