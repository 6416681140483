import React, { useState, useEffect } from 'react';
import './StudioGraphique.scss';

function StudioGraphique() {
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [mediaItems, setMediaItems] = useState([]);

  useEffect(() => {
    const fetchMediaItems = async () => {
      try {
        const response = await fetch('https://api.supra-event.fr/api/pixionautes/studio');
        const data = await response.json();
        // Map des données pour préparer le tableau des médias
        const items = data.map(media => ({
          src: media.imageUrl,
          alt: media.title
        }));
        setMediaItems(items);
      } catch (error) {
        console.error('Erreur lors de la récupération des médias :', error);
      }
    };

    fetchMediaItems();
  }, []);

  const openModal = (mediaSrc) => {
    setSelectedMedia(mediaSrc);
  };

  const closeModal = () => {
    setSelectedMedia(null);
  };

  return (
    <section id='studio-graphique' className='section-studio'>
      <img
        src={require('../../../assets/titles/STUDIO_GRAPHIQUE_2023.webp')}
        alt="Section Studio Graphique"
        className='titlestudio'
      />
      <p>
        Bienvenue dans le Studio Graphique de Pix&Motion, <br />
        Une galerie en ligne dédiée à l’infographie et au motion design. Ici, vous pourrez découvrir mes créations originales, <br />
        allant des <strong>logos</strong> aux <strong>overlays</strong>, des <strong>Emotes</strong> aux <strong>prints</strong>, en passant par des <strong>motion designs</strong> captivants. <br />
        Si vous êtes intéressé par mes services, n’hésitez pas à me contacter via mon <a href="https://discord.gg/htVnjEqqtE">serveur Discord</a> en utilisant le système de ticket ou en utilisant le formulaire de contact ci-dessous. <br />
        Je serais ravi de collaborer avec vous pour donner vie à vos idées et créer des designs graphiques uniques et de qualité.
      </p>

      <h3>LOGO • OVERLAY • EMOTE • PRINT • MOTION DESIGN</h3>
      <div className="container-graphique">
        {mediaItems.map((media, index) => (
          media.src.endsWith('.webm') ? (
            <video
              key={index}
              src={media.src}
              onClick={() => openModal(media.src)}
              className="thumbnail"
              loop
              muted
              playsInline
              autoPlay
            >
              <track kind="descriptions" label={media.alt} />
            </video>
          ) : (
            <img
              key={index}
              src={media.src}
              alt={media.alt}
              onClick={() => openModal(media.src)}
              className="thumbnail"
            />
          )
        ))}
      </div>

      {selectedMedia && (
        <div className="modalstudio" onClick={closeModal}>
          <div className="modalstudio-content" onClick={(e) => e.stopPropagation()}>
            {typeof selectedMedia === 'string' && selectedMedia.endsWith('.webm') ? (
              <video
                src={selectedMedia}
                controls
                autoPlay
                loop
                muted
                className="modal-media"
              >
                <track kind="descriptions" label="Video Description" />
              </video>
            ) : (
              <img src={selectedMedia} alt="Agrandissement" className="modal-media" />
            )}
            <button className="close-modal" onClick={closeModal}>&times;</button>
          </div>
        </div>
      )}
    </section>
  );
}

export default StudioGraphique;
