import React from 'react';
import './MainOverlay.scss';

function MainOverlay({ children }) {
  return (
    
    <div className='main-overlay'>
      {children}
    </div>
  )
}

export default MainOverlay;