import React from 'react';
import Carousel from '../Carousel/Carousel';
import FormCard from '../FormCard/FormCard';
import './CardGame.scss';

function CardGame() {
  return (
    <article id='cardgame' className='section-card'>
        <img 
          src={require('../../../assets/titles/CARD-GAME-V2 (1).webp')} 
          alt="Pix Card Games" 
          className='titlecardgame' 
        />

        <Carousel />

        <div className='cardgame-description'>
          <p>
            Dans <strong>Pix Card Game</strong>, chaque spectateur peut gagner des cartes qui lui permettront de profiter d’avantages et d’interactions 
            <br />
            exclusives lors des diffusions en direct pendant une période de validité d’un mois. 
            <br />
            Pour gagner des cartes, il suffit de participer aux jeux communautaires lancés régulièrement ! 
            <br />
            Catégories des cartes : <strong>Commune • Rare • Mythique • Ultime.</strong> 
            <br />
            <br />
            Avec ces cartes, les spectateurs peuvent déclencher des événements spéciaux, obtenir des récompenses 
            <br />
            exclusives, influencer le gameplay, ou même interagir directement avec le streamer et d’autres joueurs présents en direct. 
            <br />
            <br />
            Commandes disponibles :
            <br />
            <br />
            <strong>!mycard :</strong> Liste de toutes vos cartes 
            <br />
            <strong>!mycard nomdelacarte :</strong> Informations sur votre carte 
            <br />
            <strong>!usecard nomdelacarte :</strong> Utiliser votre carte en direct 
            <br />
            <strong>!report votremessage :</strong> Envoyer un rapport de bug 
            <br />
            <strong>!duel @votrecible :</strong> Lancer un duel à un spectateur 
            <br />
            <strong>!accepte :</strong> Accepter le duel lancé 
            <br />
            <strong>!pixcoins :</strong> Afficher votre solde 
          </p>
        </div>

        <FormCard />
    </article>
  );
}

export default CardGame;
