import React from 'react';
import './header.scss';
import Navbar from '../../../components/LesPixionautes/Navbar/Navbar';
import Logo from '../../../assets/LOGO_PIXIONAUTES.webp';
import { NavLink } from 'react-router-dom';

function Header() {
  return (
    <header className='header' role='banner'>
      <NavLink to="/" aria-label="Page d'accueil">
        <div className='logo-container'>
          <img 
            className='logo' 
            src={Logo} 
            alt='Logo de Lespixionautes' 
          />
        </div>
      </NavLink>
      <Navbar />
    </header>
  );
}

export default Header;
