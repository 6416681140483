import React from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";

// Autres pages
import HomePage from "../pages/LesPixionautes/HomePage/HomePage";
import { ErrorPage } from "../pages/LesPixionautes/ErrorPage/ErrorPage";

// LAYOUT
import { Header, Main, Footer } from "../layout/LesPixionautes/";
import MainOverlay from "../layout/Overlay/MainOverlay/MainOverlay";



// PAGE OVERLAY
import NewsGaming from "../pages/Overlay/NewsGaming/NewsGaming";
import PlayerCardValo from "../pages/Overlay/PlayerCardValo/PlayerCardValo";

const HeaderFooterLayout = () => (
  <>
    <Header />
    <Main>
      <Outlet />
    </Main>
    <Footer />
  </>
);
const HeaderFooterLayoutOverlay = () => (
  <>
    <MainOverlay>
      <Outlet />
    </MainOverlay>
  </>
);


export const router = createBrowserRouter([
  {
    element: <HeaderFooterLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
    ],
  },
  {
    element: <HeaderFooterLayoutOverlay />,
    errorElement: <ErrorPage />,
    children: [
      { path:"/overlay/newsgaming",  element: <NewsGaming />, },
      { path: "/overlay/playerscards", element: <PlayerCardValo />, }
    ]
  }
]);
