import React from 'react';
import SectionYoutube from '../../../components/LesPixionautes/Youtube/youtube';
import SectionTwitch from '../../../components/LesPixionautes/Twitch/Twitch'
import SectionCardGame from '../../../components/LesPixionautes/CardGame/CardGame'
import SectionStudioGraphique from '../../../components/LesPixionautes/StudioGraphique/StudioGraphique'
import SectionStaff from '../../../components/LesPixionautes/Staff/Staff'
import SectionPartner from '../../../components/LesPixionautes/Partner/Partner'
import './homePage.scss';

function HomePage() {
  return (
    <div className='home'>
      <SectionYoutube />
      <SectionTwitch />
      <SectionCardGame />
      <SectionStudioGraphique />
      <SectionStaff />
      <SectionPartner />
    </div>
  );
}

export default HomePage
