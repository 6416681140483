import React, { useState, useEffect } from 'react';
import './Partner.scss';

function Partner() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await fetch('https://api.supra-event.fr/api/pixionautes/partner');
        const data = await response.json();
        // Préparer les données pour le rendu
        const partnerItems = data.map(partner => ({
          src: partner.image_url,
          alt: partner.title
        }));
        setPartners(partnerItems);
      } catch (error) {
        console.error('Erreur lors de la récupération des partenaires :', error);
      }
    };

    fetchPartners();
  }, []);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <section id='partner' className='section-partner'>
      <img
        src={require('../../../assets/titles/partenaires-2023.webp')}
        alt="Section Partenaire"
        className='titlepartner'
      />
      <div className="container-partner">
        {partners.map((partner, index) => (
          <img
            key={index}
            src={partner.src}
            alt={partner.alt}
            onClick={() => openModal(partner.src)}
            className="partner-thumbnail"
          />
        ))}
      </div>

      {selectedImage && (
        <div className='modalpartner' onClick={closeModal}>
          <div className='modalpartner-content' onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Modal Partner" />
            <button className="close-modal" onClick={closeModal}>&times;</button>
          </div>
        </div>
      )}
    </section>
  );
}

export default Partner;
