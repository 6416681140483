import React, { useState, useEffect } from 'react';
import './Staff.scss';

function Staff() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [staffMembers, setStaffMembers] = useState([]);

  useEffect(() => {
    const fetchStaffMembers = async () => {
      try {
        const response = await fetch('https://api.supra-event.fr/api/pixionautes/staff');
        const data = await response.json();
        // Préparer les données pour le rendu
        const members = data.map(member => ({
          src: member.imageUrl,
          alt: member.name
        }));
        setStaffMembers(members);
      } catch (error) {
        console.error('Erreur lors de la récupération des membres du staff :', error);
      }
    };

    fetchStaffMembers();
  }, []);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <section id='staff' className='section-staff'>
      <img
        src={require('../../../assets/titles/STAFF_2023.webp')}
        alt="Section Staff"
        className='titlestaff'
      />
      <div className="container-staff">
        {staffMembers.map((member, index) => (
          <img
            key={index}
            src={member.src}
            alt={member.alt}
            onClick={() => openModal(member.src)}
            className="staff-thumbnail"
          />
        ))}
      </div>

      {selectedImage && (
        <div className='modalstaff' onClick={closeModal}>
          <div className='modalstaff-content' onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Modal Staff" />
            <button className="close-modal" onClick={closeModal}>&times;</button>
          </div>
        </div>
      )}
    </section>
  );
}

export default Staff;
