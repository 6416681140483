import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.scss';

const Carousel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cards, setCards] = useState([]);

  const settings = {
    dots: true,
    speed: 1000,
    slidesToShow: 8,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      { breakpoint: 770, settings: { slidesToShow: 3 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } }
    ]
  };

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await fetch('https://api.supra-event.fr/api/pixionautes/cardgames');
        const data = await response.json();

        // Tri des cartes par rarity
        const sortedCards = data.sort((a, b) => {
          const rarityOrder = { common: 1, rare: 2, mythique: 3, ultime: 4 };
          return rarityOrder[a.rarity] - rarityOrder[b.rarity];
        });

        setCards(sortedCards);
      } catch (error) {
        console.error('Erreur lors de la récupération des cartes :', error);
      }
    };

    fetchCards();
  }, []);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {cards.map(card => (
          <div key={card._id} className="slider-item" onClick={() => openModal(card.image_url)}>
            <img src={card.image_url} alt={card.title} />
          </div>
        ))}
      </Slider>

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <img src={selectedImage} alt="Selected" />
            <button className="close-modal" onClick={closeModal}>&times;</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
