import React, { useEffect, useState, useRef } from 'react';
import './NewsGaming.scss';
import backgroundImage from '../../../assets/GAMING_NEWS.png';

const NewsGaming = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const marqueeContainerRef = useRef(null);
  const marqueeContentRef = useRef(null);
  const animationFrameRef = useRef(null);
  const speed = 100; // Pixels per second

  // Function to fetch data
  const fetchData = async () => {
    try {
      const response = await fetch('https://dev.borezo.info/mohaa/news.php');
      if (!response.ok) {
        throw new Error('Network response was not ok: ' + response.statusText);
      }
      const data = await response.json();
      setData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 30000); // Fetch data every 30 seconds
    return () => clearInterval(intervalId); // Clean up interval on unmount
  }, []);

  useEffect(() => {
    if (marqueeContentRef.current && marqueeContainerRef.current) {
      const textWidth = marqueeContentRef.current.scrollWidth;
      const containerWidth = marqueeContainerRef.current.offsetWidth;
      setMarqueeWidth(textWidth + containerWidth);
    }
  }, [data]);

  useEffect(() => {
    const animateMarquee = (timestamp) => {
      const distance = (timestamp * speed) / 1000 % marqueeWidth;

      if (marqueeContainerRef.current) {
        const containerWidth = marqueeContainerRef.current.offsetWidth;
        setScrollPosition(containerWidth - distance);
      }

      animationFrameRef.current = requestAnimationFrame(animateMarquee);
    };

    // Start animation
    animationFrameRef.current = requestAnimationFrame(animateMarquee);

    return () => {
      // Clean up animation frame on unmount
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [marqueeWidth, speed]);

  if (loading) {
    return <div className="news-gaming-loading">Loading new articles...</div>;
  }

  if (error) {
    return <div className="news-gaming-error">Error: {error.message}</div>;
  }

  if (!data || !data.NewsGaming) {
    return <div className="news-gaming-no-data">No data available</div>;
  }

  const combinedDescriptions = data.NewsGaming.map((item, index) => {
    const expirationDate = item.expiration ? new Date(item.expiration.replace(/ /, 'T')) : null;
    const hasExpirationDate = expirationDate !== null;

    const colorClass = hasExpirationDate ? 'news-gaming-color-yellow' : (index % 2 === 0 ? 'news-gaming-color-black' : 'news-gaming-color-pink');

    return (
      <span key={index} className={colorClass} style={{ marginRight: '10px' }}>
        <span>{item.description}</span>
        <span className="news-gaming-spacer">▪</span>
      </span>
    );
  });

  return (
    <div className="news-gaming-container">
      <div className="news-gaming-background" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <div className="news-gaming-marquee-container" ref={marqueeContainerRef}>
        <div
          className="news-gaming-marquee-content"
          style={{ transform: `translateX(${scrollPosition}px)` }}
          ref={marqueeContentRef}
        >
          <p className="news-gaming-text-position">{combinedDescriptions}</p>
          <p className="news-gaming-text-position">{combinedDescriptions}</p> {/* Duplicate text for continuous marquee */}
        </div>
      </div>
    </div>
  );
};

export default NewsGaming;
