import React, { useState } from 'react';
import './FormCard.scss';

const Form = () => {
  const [formData, setFormData] = useState({
    email: '',
    pseudo: '',
    category: '',
    comment: '',
  });
  const [formStatus, setFormStatus] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.pseudo || !formData.category || !formData.comment) {
      setFormStatus('Veuillez remplir tous les champs.');
      return;
    }

    const threadId = '1149379161564119120'; 

    const threadName = `Card Game Suggestion de ${formData.pseudo}`;

    let embedColor = 0x00FF00;
    let categoryField = null; 

    if (formData.category === 'category2') {
      embedColor = 0xFF007F; 
      categoryField = null; 
    } else {
      categoryField = {
        name: 'Catégorie',
        value: formData.category,
        inline: true,
      };
    }

    const embed = {
      title: `Suggestion de ${formData.pseudo}`,
      description: formData.comment, 
      color: embedColor, 
      fields: [
        {
          name: 'Email',
          value: formData.email,
          inline: true,
        },
        categoryField,
      ].filter(Boolean),
      footer: {
        text: `Posté par ${formData.pseudo}`,
      },
    };

    const body = {
      embeds: [embed], 
      thread_id: threadId,
      thread_name: threadName, 
    };

    try {
      const response = await fetch('https://discord.com/api/webhooks/1306399385512443946/5JIkqRbQ13uNQ_NqzDuYcIzNTwme68SEdaaH8fWFaM3Mmg9-zqNyvxsLVaMT4P2D-Lwe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        setFormStatus('Merci pour votre suggestion !');
      } else {
        const errorData = await response.json();
        setFormStatus(`Erreur: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi au webhook:', error);
      setFormStatus('Une erreur est survenue lors de l\'envoi de la suggestion.');
    }

    setFormData({
      email: '',
      pseudo: '',
      category: '',
      comment: '',
    });
  };

  return (
    <form className="formcard" onSubmit={handleSubmit}>
      <h2>AVEZ-VOUS UNE SUGGESTION ?</h2>
      {formStatus && <p className="form-status">{formStatus}</p>}

      <div className="form-group">
        <label htmlFor="email-suggestion">Email</label>
        <input
          type="email"
          id="email-suggestion"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="pseudo-suggestion">Pseudo</label>
        <input
          type="text"
          id="pseudo-suggestion"
          name="pseudo"
          value={formData.pseudo}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="category-suggestion">Catégorie</label>
        <select
          id="category-suggestion"
          name="category"
          value={formData.category}
          onChange={handleInputChange}
          required
        >
          <option value="">Sélectionner une catégorie</option>
          <option value="category1">Nouvelle Carte</option>
          <option value="category2">Nouvelle Fonctionnalité</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="comment-suggestion">Commentaire ou message</label>
        <textarea
          id="comment-suggestion"
          name="comment"
          value={formData.comment}
          onChange={handleInputChange}
          required
        />
      </div>

      <button className='button-53' type="submit">Envoyer</button>
    </form>
  );
};

export default Form;
